import React from "react"

import SEO from "../components/Base/SEO"
import extractAboutData from "../services/extractAboutData"
import InnerPageBanner from "../components/Shared/InnerPageBanner"
import Layout from "../components/Base/Layout"
import Quadrant from "../components/AimBox/Quadrant/Quadrant"
import Intro2 from "../components/AimBox/Quadrant/Intro2"

export default function () {
  const data = extractAboutData()
  return (
    <Layout>
      <SEO title={"AIM Box"} />
      <InnerPageBanner headingText={"AIM Box"} linkText={"AIM Box"} />
      <Intro2 />
    </Layout>
  )
}
